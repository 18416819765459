$MAIN_COLOR: #22866c;
$MAIN_COLOR_OPACITY: rgba(34, 134, 108, 0.32);
$SECONDARY_COLOR: #3dba95;
$SECONDARY_COLOR_OPACITY: #000000;
$MENU_ITEM_ACTIVE: rgba(255, 255, 255, 0.13);
$MENU_BORDER: #ff8200;
$BACKGROUND_COLOR: #fafbfc;
$ERROR_RED: #bb5454;
$BLACK_TEXT_COLOR: #1d1e21;
$GRAY_TEXT_COLOR: #858994;
$SECONDARY_GRAY_TEXT_COLOR: #bbbdc3;
$WHITE_TEXT_COLOR: #f1f2f3;
$SECTION_COLOR: #0b5240;
$MENU_ICON_ACTIVE: #f1f2f3;
// $MENU_CLOSE_ICON_COLOR: white;
$MOBILE_MENU_OPEN_ICON_COLOR: #22866c;
$MOBILE_MENU_HEADER_HEIGHT: 58;

:export {
  MAIN_COLOR: $MAIN_COLOR;
  MAIN_COLOR_OPACITY: $MAIN_COLOR_OPACITY;
  SECONDARY_COLOR: $SECONDARY_COLOR;
  MENU_ITEM_ACTIVE: $MENU_ITEM_ACTIVE;
  MENU_BORDER: $MENU_BORDER;
  BACKGROUND_COLOR: $BACKGROUND_COLOR;
  ERROR_RED: $ERROR_RED;
  SECONDARY_COLOR_OPACITY: $SECONDARY_COLOR_OPACITY;
  BLACK_TEXT_COLOR: $BLACK_TEXT_COLOR;
  GRAY_TEXT_COLOR: $GRAY_TEXT_COLOR;
  SECONDARY_GRAY_TEXT_COLOR: $SECONDARY_GRAY_TEXT_COLOR;
  WHITE_TEXT_COLOR: $WHITE_TEXT_COLOR;
  SECTION_COLOR: $SECTION_COLOR;
  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  // MENU_CLOSE_ICON_COLOR: $MENU_CLOSE_ICON_COLOR;
  MOBILE_MENU_OPEN_ICON_COLOR: $MOBILE_MENU_OPEN_ICON_COLOR;
  MOBILE_MENU_HEADER_HEIGHT: $MOBILE_MENU_HEADER_HEIGHT;
}

body {
  --MAIN_COLOR: #{$MAIN_COLOR};
  --MAIN_COLOR_OPACITY: #{$MAIN_COLOR_OPACITY};
  --SECONDARY_COLOR: #{$SECONDARY_COLOR};
  --MENU_BORDER: #{$MENU_BORDER};
  --BACKGROUND_COLOR: #{$BACKGROUND_COLOR};
  --ERROR_RED: #{$ERROR_RED};
  --SECONDARY_COLOR_OPACITY: #{$SECONDARY_COLOR_OPACITY};
  --BLACK_TEXT_COLOR: #{$BLACK_TEXT_COLOR};
  --GRAY_TEXT_COLOR: #{$GRAY_TEXT_COLOR};
  --SECONDARY_GRAY_TEXT_COLOR: #{$SECONDARY_GRAY_TEXT_COLOR};
  --WHITE_TEXT_COLOR: #{$WHITE_TEXT_COLOR};
  --SECTION_COLOR: #{$SECTION_COLOR};
}
